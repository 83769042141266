import { Injectable } from '@angular/core';
import { ModalController } from '@ionic/angular/standalone';
import { DebugDialog } from 'src/app/ui/dialogs/debug/debug.dialog';
import { GegnerId } from 'src/app/services/gegner/gegner-enums';
import {
  AusruestungTyp,
  ItemId,
  ItemSeltenheit,
} from 'src/app/services/items/item-enums';
import { ItemReferenz } from 'src/app/services/items/item-referenz';
import { SchmiedeService } from 'src/app/services/items/schmiede.service';
import { Tracking } from 'src/app/services/tracking/tracking';
import {
  TrackedActivity,
  TrackingId,
} from 'src/app/services/tracking/tracking-types';
import { TypeHelper } from 'src/app/services/type-helper';
import { environment } from 'src/environments/environment';
import type { Item } from 'src/app/services/items/item';
import type { Spieler } from 'src/app/services/spieler/spieler';

@Injectable({
  providedIn: 'root',
})
export class DebugService {
  private modalOffen: boolean = false;

  constructor(
    private modalController: ModalController,
    private schmiede: SchmiedeService
  ) {}

  public oeffneDebugMenu(): void {
    if (environment.production || this.modalOffen) {
      return;
    }

    this.modalController
      .create({
        component: DebugDialog,
        id: DebugDialog.ModalID,
      })
      .then((modal) => {
        modal.onDidDismiss().then((ev) => {
          this.modalOffen = false;
        });

        this.modalOffen = true;
        modal.present();
      });
  }

  public spawnItem(
    spieler: Spieler,
    itemId: ItemId,
    anzahl: number,
    seltenheit: ItemSeltenheit,
    level: number,
    upgradeLevel: number,
    sterne: number,
    addBonus: number,
    gegnerId: GegnerId
  ): void {
    const referenz = new ItemReferenz({
      id: itemId,
      anzahl: anzahl,
      seltenheit: seltenheit,
      level: level,
      upgradeLevel: upgradeLevel,
      sterne: sterne,
      additiveBonus: itemId == ItemId.Pet ? addBonus : undefined,
      gegnerRef: gegnerId,
    });

    spieler.addLootToInventory([referenz.createItem()]);
  }

  public removeItem(spieler: Spieler, inventarIndex: number): void {
    if (inventarIndex < 0 || inventarIndex >= spieler.inventar.length) {
      return;
    }

    spieler.inventar[inventarIndex]?.destroy();
    spieler.inventar.splice(inventarIndex, 1);
  }

  /** Truhen schreiben */
  public autoTruhen(spieler: Spieler, truhenAnteil: number): void {
    // Truhen Favorit setzen
    if (spieler.chestTracking.favoritId == '') {
      spieler.chestTracking.setFavorit(spieler.chestTracking.truhen[0]);
    }

    // Truhen erfolgreiche Tage setzen
    for (let truhe of spieler.chestTracking.truhen) {
      for (let i = 0; i < 7; i++) {
        truhe.erfolgreicheTage[i] = Math.random() < truhenAnteil;
      }
    }
  }

  /** Aktivitäten schreiben */
  public autoTracken(
    spieler: Spieler,
    trackingMedianPerDay: number,
    trackingDeviationPerDay: number
  ): void {
    // Aktivitäten tracken
    const factor: number =
      Tracking.PredefinedActivities.find((act) => act.id == TrackingId.Work)
        ?.pointsPerUnit ?? 1 / 60;

    for (let activity of spieler.activityTracking) {
      let units: number = trackingMedianPerDay;
      units += (Math.random() * 2 - 1) * trackingDeviationPerDay;
      units /= factor;

      units = Math.max(0, units);

      activity.activities = [
        new TrackedActivity({
          id: TrackingId.Work,
          trackedUnits: units,
        }),
      ];
    }
  }

  /** Bestes verfügbares EQ ausrüsten */
  public autoEquipBestPossibleItems(
    spieler: Spieler,
    focusTyp: AusruestungTyp = AusruestungTyp.Kein
  ): void {
    // Base-Damage mit Aktivitäten und Level
    const baseDmg: number = spieler.getNoEquipFightingPoints();

    let types: AusruestungTyp[] = [focusTyp];
    if (focusTyp == AusruestungTyp.Kein) {
      types = TypeHelper.enumValues(AusruestungTyp);
    }

    // EQ ausrüsten
    for (let type of types) {
      if (type == AusruestungTyp.Kein) continue;

      let currentRef = spieler.ausruestung.find(
        (item) => item.getPublishedItem().ausruestungTyp == type
      );
      let bonus: number =
        currentRef?.getPublishedItem().getSingleItemBonus(baseDmg) ?? baseDmg;

      let availableRefs = spieler.inventar.filter(
        (item) => item.getPublishedItem().ausruestungTyp == type
      );

      let [bestCandidateRef, bestBonus] = TypeHelper.findExtreme(
        availableRefs,
        (ref) => ref.getPublishedItem().getSingleItemBonus(baseDmg),
        true,
        Math.max(bonus, baseDmg)
      );

      if (bestBonus > bonus) {
        spieler.unequipItem(currentRef);
        spieler.equipItem(bestCandidateRef);
      }
    }
  }

  /** Leere Equipment Slots mit gecraftetem EQ füllen */
  public autoCraftAndEquipEmptySlots(spieler: Spieler): void {
    this.autoEquipBestPossibleItems(spieler);

    // Base-Damage mit Aktivitäten und Level
    const baseDmg: number = spieler.getNoEquipFightingPoints();

    // Zeug craften
    let items: Item[] = [];
    do {
      const verfuegbareCrafts: Item[] = this.schmiede
        .verfuegbareCrafts(spieler)
        .map((item) => item.getPublishedItem());
      const ausruestung: AusruestungTyp[] = spieler.ausruestung.map(
        (item) => item.getPublishedItem().ausruestungTyp
      );

      /** Typen ohne ausgerüstetes Item, die wir craften können */
      items = verfuegbareCrafts.filter(
        (item) => !ausruestung.includes(item.ausruestungTyp)
      );

      const [target, bonus] = TypeHelper.findExtreme(
        items,
        (item) => item.getSingleItemBonus(baseDmg),
        true,
        baseDmg
      );

      // wenn das bestmögliche Item keinen Bonus bringt, oder wir nicht craften konnten -> abbrechen
      if (
        target == null ||
        bonus <= baseDmg ||
        !this.schmiede.craftItem(spieler, target.ausruestungTyp)
      ) {
        break;
      }

      const currentRef = spieler.inventar.find((item) =>
        item.isCompatibleWith(target)
      );

      if (!spieler.equipItem(currentRef)) break;
    } while (items.length > 1);
  }

  public sollteHammerBenutzen(spieler: Spieler, item: ItemReferenz): boolean {
    return (
      (item.seltenheit ?? ItemSeltenheit.Gewoehnlich) >=
      ItemSeltenheit.Ungewoehnlich
    );
  }

  /**
   * Alles im Inventar so weit kombinieren, wie es geht.
   * Bestehendes EQ mit Hammer verbessern.
   */
  public autoCombineEquipment(spieler: Spieler): void {
    const baseDmg: number = spieler.getNoEquipFightingPoints();
    let items: ItemReferenz[] = [];

    let somethingChanged: boolean = true;
    let lastChange: AusruestungTyp = AusruestungTyp.Kein;

    do {
      if (somethingChanged) {
        this.autoEquipBestPossibleItems(spieler, lastChange);
      }

      // Items die wir upgraden können
      items = spieler.ausruestung
        .concat(spieler.inventar)
        .filter((item) =>
          this.schmiede.kannNeuschmieden(
            spieler,
            item,
            undefined,
            this.sollteHammerBenutzen(spieler, item)
          )
        );

      // bestes Item aufwerten
      const [target, targetBonus] = TypeHelper.findExtreme(
        items,
        (item) => item.getPublishedItem().getSingleItemBonus(baseDmg),
        true,
        baseDmg
      );
      if (target == null) break;

      const hammer = this.sollteHammerBenutzen(spieler, target);

      const opferList = spieler.inventar.filter((opferRef) =>
        this.schmiede.kannNeuschmieden(spieler, target, opferRef, hammer)
      );
      // schlechtestes Item opfern
      const [opfer, opferBonus] = TypeHelper.findExtreme(
        opferList,
        (ref) => ref.getPublishedItem().getSingleItemBonus(baseDmg),
        false,
        Infinity
      );

      somethingChanged = this.schmiede.neuschmieden(
        spieler,
        target,
        opfer,
        hammer
      );
      lastChange = target.getPublishedItem().ausruestungTyp;
    } while (items.length > 0);
  }

  /** EQ zum Neuschmieden craften */
  public autoCraftAndCombine(spieler: Spieler): void {
    const baseDmg: number = spieler.getNoEquipFightingPoints();
    let items: Item[] = [];

    do {
      this.autoCombineEquipment(spieler);

      const verfuegbareCrafts: AusruestungTyp[] = this.schmiede
        .verfuegbareCrafts(spieler)
        .map((item) => item.getPublishedItem().ausruestungTyp);

      // Items zu denen wir was craften könnten
      items = spieler.ausruestung
        .concat(spieler.inventar)
        .map((ref) => ref.getPublishedItem())
        .filter((item) => verfuegbareCrafts.includes(item.ausruestungTyp));

      // gruppiere alle Items nach Ausrüstungstyp
      const itemsByType = TypeHelper.groupBy(
        items,
        (ref) => ref.ausruestungTyp
      );

      // finde für jeden Typ das seltenste Item
      items = [];
      itemsByType.forEach((typeItems, type) => {
        const [best, bonus] = TypeHelper.findExtreme(
          typeItems,
          (item) => item.seltenheit,
          true,
          -1
        );
        if (best != null) {
          items.push(best);
        }
      });

      // Ausrüstung zu der wir mehr craften können
      items = items.filter(
        (item) =>
          // Darf nicht auf Maximum sein
          item.seltenheit != ItemSeltenheit.Legendaer &&
          item.seltenheit != ItemSeltenheit.Mythisch
      );

      const [target, bonus] = TypeHelper.findExtreme(
        items,
        (item) => item.getSingleItemBonus(baseDmg),
        true,
        baseDmg
      );
      if (
        target == null ||
        !this.schmiede.craftItem(spieler, target.ausruestungTyp)
      ) {
        break;
      }
    } while (items.length > 0);
  }

  /** EQ uppen */
  public autoUp(spieler: Spieler): void {
    const baseDmg: number = spieler.getNoEquipFightingPoints();
    let items: ItemReferenz[] = [];

    this.autoEquipBestPossibleItems(spieler);

    do {
      // gruppiere upgradable Items nach Ausrüstungstyp
      const itemsByType = TypeHelper.groupBy(
        spieler.ausruestung.concat(spieler.inventar),
        (ref) => ref.getPublishedItem().ausruestungTyp
      );
      itemsByType.delete(AusruestungTyp.Kein);

      // finde für jeden Typ das seltenste Item
      items = [];
      itemsByType.forEach((refs, type) => {
        const [best, bonus] = TypeHelper.findExtreme(
          refs,
          (ref) => ref.seltenheit ?? ItemSeltenheit.Gewoehnlich,
          true,
          -1
        );
        if (best != null) {
          items.push(best);
        }
      });

      // welche davon können wir upgraden?
      items = items.filter(
        (ref) =>
          this.schmiede.getUpgradeMaterialDisplay(spieler, ref, false) != null
      );

      // finde das beste Item aus denen
      const [target, bonus] = TypeHelper.findExtreme(
        items,
        (item) => item.getPublishedItem().getSingleItemBonus(baseDmg),
        true,
        baseDmg
      );

      if (target == null) {
        break;
      }

      if (this.schmiede.upgradeEquipment(spieler, target, false)) {
        this.autoEquipBestPossibleItems(
          spieler,
          target.getPublishedItem().ausruestungTyp
        );
      }
    } while (items.length > 0);
  }

  /** Pets verbessern */
  public autoTrainPets(spieler: Spieler): void {
    const baseDmg: number = spieler.getNoEquipFightingPoints();
    let pets: ItemReferenz[] = [];

    if (this.schmiede.getMainPet(spieler) == null) {
      return;
    }

    do {
      pets = this.schmiede.getOtherPets(spieler);

      // schwächstes Pet opfern
      const [opfer, bonus] = TypeHelper.findExtreme(
        pets,
        (ref) => ref.getPublishedItem().getSingleItemBonus(baseDmg),
        false,
        Infinity
      );
      if (!this.schmiede.fusionierePet(spieler, opfer)) {
        break;
      }
    } while (pets.length > 1);
  }

  public autoPlay(
    spieler: Spieler,
    truhenAnteil: number,
    trackingMedianPerDay: number,
    trackingDeviationPerDay: number
  ): void {
    spieler.maxPetOrbs = 10;

    this.autoTruhen(spieler, truhenAnteil);
    this.autoTracken(spieler, trackingMedianPerDay, trackingDeviationPerDay);

    [
      this.autoCraftAndEquipEmptySlots,
      this.autoCraftAndCombine,
      this.autoUp,
      this.autoTrainPets,
    ].forEach((func) => func.call(this, spieler));

    // TODO: AutoPlay funktioniert wahrscheinlich nicht optimal, wenn es Items von mehreren Karten gibt
    /*
      Anwenden vom Schmiedehammer ggf noch stärker Kontextabhängig machen
      Auswahl von Items/Crafting usw noch kleinteiliger auswählen/planen?
    */
  }

  public timetravelOneWeek(
    spieler: Spieler,
    autoPlayBeforeTravel: boolean,
    moveAfterTravel: boolean,
    truhenAnteil: number,
    trackingMedianPerDay: number,
    trackingDeviationPerDay: number
  ): void {
    if (autoPlayBeforeTravel) {
      this.autoPlay(
        spieler,
        truhenAnteil,
        trackingMedianPerDay,
        trackingDeviationPerDay
      );
    }

    const target: Date = new Date(spieler.aktiveWoche);
    target.setDate(target.getDate() + 7);
    spieler.fightOneWeek(true, target);

    if (moveAfterTravel && spieler.feldNummer < 9) {
      spieler.feldNummer++;
    }
  }
}
