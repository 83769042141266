<ion-header>
  <ion-toolbar>
    <ion-title>Glücksrad</ion-title>
    <ion-buttons slot="end">
      <ion-button (click)="this.schliessen()">
        <ion-icon slot="icon-only" name="close"></ion-icon>
      </ion-button>
    </ion-buttons>
  </ion-toolbar>
</ion-header>

<ion-content>
  <div *ngIf="this.loot.length <= 0">Keine Items?!</div>

  <div class="spinner-base" *ngIf="this.loot.length > 0">
    <div class="spinner-container">
      <div class="animation" [class.run]="this.running">
        <app-item-display
          *ngFor="let item of this.loot"
          [item]="item"
          [style.--item-shift]="this.itemShift"
        ></app-item-display>
      </div>

      <div class="indicator"></div>
    </div>

    <ion-button (click)="this.running = true" [disabled]="this.running">
      Spin!
    </ion-button>
  </div>
</ion-content>
