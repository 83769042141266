import { Component, Input } from '@angular/core';
import { ItemReferenz } from 'src/app/services/items/item-referenz';
import {
  IonButtons,
  IonButton,
  IonIcon,
  IonTitle,
  IonToolbar,
  IonHeader,
  ModalController,
  IonContent,
} from '@ionic/angular/standalone';
import { ItemListeComponent } from 'src/app/ui/components/item-liste/item-liste.component';
import { NgFor, NgIf } from '@angular/common';
import { ItemDisplayComponent } from '../../components/item-display/item-display.component';

@Component({
  selector: 'app-loot-spinner',
  templateUrl: './loot-spinner.dialog.html',
  styleUrls: ['./loot-spinner.dialog.scss'],
  standalone: true,
  imports: [
    IonContent,
    IonHeader,
    IonToolbar,
    IonTitle,
    IonIcon,
    IonButton,
    IonButtons,
    ItemListeComponent,
    NgIf,
    NgFor,
    ItemDisplayComponent,
  ],
})
export class LootSpinnerDialog {
  @Input({ required: true })
  public loot: ItemReferenz[] = [];

  public running: boolean = false;

  public itemShift: string = Math.floor(Math.random() * 90 + 10) + '%';

  public constructor(private modalController: ModalController) {}

  public schliessen(): void {
    this.modalController.dismiss(null, 'close');
  }
}
