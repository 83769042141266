import { Component } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule } from '@angular/forms';
import {
  IonContent,
  IonHeader,
  IonTitle,
  IonToolbar,
  IonButton,
  IonButtons,
  IonIcon,
  ModalController,
  IonSelect,
  IonSelectOption,
  IonList,
  IonItem,
  IonInput,
  IonItemGroup,
  IonItemDivider,
  IonLabel,
  IonCheckbox,
  LoadingController,
} from '@ionic/angular/standalone';
import {
  AusruestungTyp,
  ItemId,
  ItemSeltenheit,
} from 'src/app/services/items/item-enums';
import { TypeHelper } from 'src/app/services/type-helper';
import { ItemListe } from 'src/app/data/item-liste';
import { Spieler } from 'src/app/services/spieler/spieler';
import { GegnerId } from 'src/app/services/gegner/gegner-enums';
import { DebugService } from 'src/app/services/debug/debug.service';
import { DebugLuck } from 'src/app/services/debug/debug-luck';
import { Item } from 'src/app/services/items/item';
import { SpielerverwaltungService } from 'src/app/services/spieler/spielerverwaltung.service';
import { LootSpinnerDialog } from 'src/app/ui/dialogs/loot-spinner/loot-spinner.dialog';
import { ItemReferenz } from 'src/app/services/items/item-referenz';

@Component({
  selector: 'app-debug',
  templateUrl: './debug.dialog.html',
  styleUrls: ['./debug.dialog.scss'],
  standalone: true,
  imports: [
    IonCheckbox,
    IonLabel,
    IonItemDivider,
    IonItemGroup,
    IonInput,
    IonItem,
    IonList,
    IonSelect,
    IonSelectOption,
    IonIcon,
    IonButtons,
    IonButton,
    IonContent,
    IonHeader,
    IonTitle,
    IonToolbar,
    CommonModule,
    FormsModule,
  ],
})
export class DebugDialog {
  public static readonly ModalID: string = 'modal_debug';
  public readonly ItemIDs: (keyof typeof ItemId)[] =
    TypeHelper.enumKeys(ItemId);
  public readonly ItemSeltenheiten: (keyof typeof ItemSeltenheit)[] =
    TypeHelper.enumKeys(ItemSeltenheit);
  public readonly GegnerIDs: (keyof typeof GegnerId)[] =
    TypeHelper.enumKeys(GegnerId);
  public readonly StaticDebugLuck: typeof DebugLuck = DebugLuck;

  public readonly today: Date = new Date();

  private loader?: HTMLIonLoadingElement;

  public get spieler(): Spieler | undefined {
    return Spieler.MainCharacter();
  }

  //#region ItemSpawner
  public spawnID: keyof typeof ItemId = 'Kein';
  public spawnSeltenheit: keyof typeof ItemSeltenheit = 'Gewoehnlich';
  public spawnGegner: keyof typeof GegnerId = 'Kein';
  public spawnAnzahl: number = 1;
  public spawnLevel: number = 1;
  public spawnUpgrade: number = 0;
  public spawnSterne: number = 0;
  public spawnAddBonus: number = 0;

  public currentStackSize: number = 1;
  //#endregion

  //#region ItemDeleter
  public inventarIndex: number = 0;
  //#endregion

  //#region TimeTravel
  public autoPlayBeforeTravel: boolean = true;
  public moveAfterTravel: boolean = true;
  public truhenAnteil: number = 1;
  public trackingMedianPerDay: number = 8;
  public trackingDeviationPerDay: number = 1;
  //#endregion

  //#region Simulation
  public playerCount: number = 1;
  public weekCount: number = 1;
  //#endregion

  public constructor(
    private modalController: ModalController,
    private loadingController: LoadingController,
    private spielerverwaltungService: SpielerverwaltungService,
    private debugService: DebugService
  ) {}

  public neueIdGewaehlt(): void {
    const item = ItemListe.getById(ItemId[this.spawnID]);
    this.currentStackSize = item.stackSize;
  }

  public spawnItem(): void {
    if (this.spieler == null) return;

    this.debugService.spawnItem(
      this.spieler,
      ItemId[this.spawnID],
      this.spawnAnzahl,
      ItemSeltenheit[this.spawnSeltenheit],
      this.spawnLevel,
      this.spawnUpgrade,
      this.spawnSterne,
      this.spawnAddBonus,
      GegnerId[this.spawnGegner]
    );
  }

  public removeItem(): void {
    if (this.spieler == null) {
      return;
    }

    this.debugService.removeItem(this.spieler, this.inventarIndex);
  }

  public formatDate(date: Date): string {
    return date.toLocaleString('de-DE', {
      year: 'numeric',
      month: '2-digit',
      day: '2-digit',
      hour: '2-digit',
      minute: '2-digit',
      second: '2-digit',
      hour12: false,
    });
  }

  public autoPlay(target?: Spieler): void {
    if (target == null) {
      return;
    }

    this.debugService.autoPlay(
      target,
      this.truhenAnteil,
      this.trackingMedianPerDay,
      this.trackingDeviationPerDay
    );
  }

  public timetravelOneWeek(target?: Spieler): void {
    if (target == null) {
      return;
    }

    this.debugService.timetravelOneWeek(
      target,
      this.autoPlayBeforeTravel,
      this.moveAfterTravel,
      this.truhenAnteil,
      this.trackingMedianPerDay,
      this.trackingDeviationPerDay
    );
  }

  private simulationStep(
    spielerErgebnis: Spieler[],
    spielerIndex: number
  ): void {
    if (spielerIndex >= this.playerCount) {
      this.setSimulationProgress(spielerIndex + 1, 1);
      setTimeout(() => this.simulationEnd(spielerErgebnis));
      return;
    }

    const start: number = performance.now();
    const aktuellerSpieler: Spieler = spielerErgebnis[spielerIndex];

    for (let w = aktuellerSpieler.statistik.length; w < this.weekCount; w++) {
      this.timetravelOneWeek(aktuellerSpieler);

      if (performance.now() - start > 1000 && w + 1 < this.weekCount) {
        // wir simulieren diesen Spieler schon über 1s
        this.setSimulationProgress(spielerIndex + 1, w + 1);
        setTimeout(() => this.simulationStep(spielerErgebnis, spielerIndex));
        return;
      }
    }

    // wenn wir AutoPlay an haben, dann spielen wir noch nach der letzten Woche zu Ende
    if (this.autoPlayBeforeTravel) {
      this.autoPlay(aktuellerSpieler);
    }

    spielerIndex++;

    // Loader hier aktualisieren, damit die Anzeige zwischen den Timeouts aktualisiert werden kann
    this.setSimulationProgress(spielerIndex + 1, 1);
    setTimeout(() => this.simulationStep(spielerErgebnis, spielerIndex));
  }

  private simulationEnd(spielerErgebnis: Spieler[]): void {
    const endTime: Date = new Date();
    const ms: number =
      endTime.getTime() - spielerErgebnis[0].aktivSeit.getTime();

    console.log(
      `Simulation:
  ${this.playerCount} Spieler,
  ${this.weekCount} Wochen,
  ${this.trackingMedianPerDay}±${this.trackingDeviationPerDay} TP,
  ${this.truhenAnteil} Truhen,
  AutoPlay: ${this.autoPlayBeforeTravel},
  AutoMove: ${this.moveAfterTravel}
  @ ${ms} ms`
    );

    /*
    Testdaten Statistiken (Massensimulation)
      Index, TP, Truhenanteil (Settings)
      Level, Gesamt-EP
      Kampfpunkte
      Gesamt besiegte Gegner und gesamt geöffnete Truhen
      Ausrüstung (in lesbar (Seltenheit+Uplevel) und als Bonus)
      übriges EQ im Inventar (gesamt Bonus pro Slot)
      Essenzen, Materialien, Schmiedehammer, Orbs
      Drops-Gesamt aus Truhen und Gegner (seperat) ???
    */

    const ausruestungSpalten: AusruestungTyp[] = [
      AusruestungTyp.Helm,
      AusruestungTyp.Ruestung,
      AusruestungTyp.Handschuhe,
      AusruestungTyp.Schuhe,
      AusruestungTyp.Halskette,
      AusruestungTyp.Guertel,
      AusruestungTyp.Ring,
      AusruestungTyp.HandRechts,
      AusruestungTyp.Tier,
    ];

    const seltenheitText: string[] = ['g', 'u', 's', 'e', 'l', 'm'];

    const log: string[] = spielerErgebnis.map((spieler: Spieler): string => {
      const result: (string | number)[] = [
        spieler.name,
        this.trackingMedianPerDay,
        this.trackingDeviationPerDay,
        this.truhenAnteil,
        this.weekCount,
        this.autoPlayBeforeTravel ? 1 : 0,
        this.moveAfterTravel ? 1 : 0,
        spieler.level,
        spieler.erfahrungUebrig,
        spieler.level +
          spieler.erfahrungUebrig / spieler.getExpRequiredForLevelUp(),
        spieler.getLevelBonus(),
        spieler.getNoEquipFightingPoints(),
        spieler.getTotalAdditiveBonus(),
        spieler.getTotalMultiplicativeBonus(),
        spieler.getFightingPoints(),
        spieler.getAllTimeKilledEnemies(),
        spieler.getAllTimeOpenedChests(),
      ];

      const ausruestungItems: Item[] = spieler.ausruestung.map((ref) =>
        ref.getPublishedItem()
      );
      const inventarItems: Item[] = spieler.inventar.map((ref) =>
        ref.getPublishedItem()
      );

      for (const typ of ausruestungSpalten) {
        const ausgeruestet: Item | undefined = ausruestungItems.find(
          (ref) => ref.ausruestungTyp == typ
        );
        const restItems: Item[] = inventarItems.filter(
          (item) => item.ausruestungTyp == typ
        );
        const restBonus: number = restItems.reduce(
          (sum, item) =>
            sum +
            item.getAdditiveEquipmentBonus() +
            item.getMultiplicativeEquipmentBonus() -
            1,
          0
        );

        if (ausgeruestet != null) {
          result.push(
            `${seltenheitText[ausgeruestet.seltenheit]}+${
              (ausgeruestet.equipmentStats?.upgradeLevel ?? 0) +
              ausgeruestet.sterne
            }`,
            ausgeruestet.getAdditiveEquipmentBonus() +
              ausgeruestet.getMultiplicativeEquipmentBonus() -
              1
          );
        } else {
          result.push('', '0');
        }
        result.push(restItems.length, restBonus);
      }

      return result.join(';');
    });

    this.spielerverwaltungService.exportText(
      log.join('\n').replace(/\./g, ',')
    );

    if (this.loader != null) {
      this.loader.dismiss();
    }
  }

  private setSimulationProgress(spielerIndex: number, wochenIndex: number) {
    // Loader hier aktualisieren, damit die Anzeige zwischen den Timeouts aktualisiert werden kann
    if (this.loader != null) {
      let message: string[] = [];

      if (spielerIndex <= this.playerCount) {
        message = [
          'Simuliere...',
          `Spieler ${spielerIndex} von ${this.playerCount}`,
          `Woche ${wochenIndex} von ${this.weekCount}`,
        ];
      } else {
        message = ['Erstelle Ergebnis...'];
      }

      this.loader.message = message.join('\n');
    }
  }

  public simulate(): void {
    const alleSpieler: Spieler[] = [];

    // alle Spieler erstellen
    for (let p = 0; p < this.playerCount; p++) {
      alleSpieler.push(new Spieler({ name: '' + (p + 1) }));
    }

    this.loadingController
      .create({
        message: 'Starte Simulation...',
        cssClass: ['loading-progress'],
      })
      .then((loader) => {
        this.loader = loader;
        this.setSimulationProgress(1, 1);
        loader
          .present()
          .then(() => setTimeout(() => this.simulationStep(alleSpieler, 0)));
      });
  }

  public zeigeLootSpinner(): void {
    const ids: ItemId[] = [
      ItemId.HelmLv1,
      ItemId.HandschuheLv1,
      ItemId.RuestungLv1,
      ItemId.SchuheLv1,
      ItemId.HalsketteLv1,
      ItemId.RingLv1,
      ItemId.WaffeLv1,
      ItemId.GuertelLv1,
    ];

    const seltenheiten: ItemSeltenheit[] = [
      ItemSeltenheit.Gewoehnlich,
      ItemSeltenheit.Ungewoehnlich,
      ItemSeltenheit.Selten,
      ItemSeltenheit.Episch,
      ItemSeltenheit.Legendaer,
    ];

    const items: ItemReferenz[] = new Array(52);
    for (let i = 0; i < 25; i++) {
      items[i] = new ItemReferenz({
        id: ids[i % ids.length],
        seltenheit: seltenheiten[i % seltenheiten.length],
      });
      items[26 + i] = items[i];
    }

    items[25] = new ItemReferenz({
      id: ItemId.Missing,
      seltenheit: ItemSeltenheit.Mythisch,
    });
    items[51] = items[25];

    this.modalController
      .create({
        component: LootSpinnerDialog,
        componentProps: {
          loot: items,
        },
      })
      .then((modal) => modal.present());
  }

  public schliessen(): void {
    this.modalController.dismiss(null, 'close', DebugDialog.ModalID);
  }

  // TODO: Spieler zurücksetzen Knopf
  // TODO: Simulation abbrechen können, ggf Progress usw auf modal umstellen
}
