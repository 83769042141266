<ion-header>
  <ion-toolbar>
    <ion-title>Debug</ion-title>
    <ion-buttons slot="end">
      <ion-button (click)="this.schliessen()">
        <ion-icon slot="icon-only" name="close"></ion-icon>
      </ion-button>
    </ion-buttons>
  </ion-toolbar>
</ion-header>

<ion-content>
  <ng-container *ngIf="this.spieler != null">
    <!-- Inventar-Editor -->
    <ion-list>
      <ion-item-group>
        <ion-item-divider>
          <ion-label>Item hinzufügen</ion-label>
        </ion-item-divider>
        <ion-item>
          <!-- Item-ID -->
          <ion-select
            [(ngModel)]="this.spawnID"
            (ionChange)="this.neueIdGewaehlt()"
            label="Item-ID"
            labelPlacement="stacked"
          >
            <ion-select-option *ngFor="let id of this.ItemIDs" [value]="id">
              {{ id }}
            </ion-select-option>
          </ion-select>
        </ion-item>
        <ion-item>
          <!-- Seltenheit -->
          <ion-select
            [(ngModel)]="this.spawnSeltenheit"
            label="Item-Seltenheit"
            labelPlacement="stacked"
          >
            <ion-select-option
              *ngFor="let val of this.ItemSeltenheiten"
              [value]="val"
            >
              {{ val }}
            </ion-select-option>
          </ion-select>
        </ion-item>
        <ion-item>
          <!-- Anzahl -->
          <ion-input
            type="number"
            [min]="1"
            [max]="this.currentStackSize"
            [(ngModel)]="this.spawnAnzahl"
            label="Item-Anzahl"
            labelPlacement="stacked"
          ></ion-input>
        </ion-item>
        <ion-item>
          <!-- Level -->
          <ion-input
            type="number"
            [min]="1"
            [(ngModel)]="this.spawnLevel"
            label="Item-Level"
            labelPlacement="stacked"
          ></ion-input>
        </ion-item>
        <ion-item>
          <!-- Upgrade -->
          <ion-input
            type="number"
            [min]="1"
            [max]="9"
            [(ngModel)]="this.spawnUpgrade"
            label="Item-Upgrade"
            labelPlacement="stacked"
          ></ion-input>
        </ion-item>
        <ion-item>
          <!-- Sterne -->
          <ion-input
            type="number"
            [min]="1"
            [(ngModel)]="this.spawnSterne"
            label="Item-Sterne"
            labelPlacement="stacked"
          ></ion-input>
        </ion-item>
        <ion-item>
          <!-- AddBonus -->
          <ion-input
            type="number"
            [min]="1"
            [(ngModel)]="this.spawnAddBonus"
            label="Item-AdditiveBonus (Pet only)"
            labelPlacement="stacked"
          ></ion-input>
        </ion-item>
        <ion-item>
          <!-- Gegner-ID -->
          <ion-select
            [(ngModel)]="this.spawnGegner"
            label="Gegner-Referenz-ID"
            labelPlacement="stacked"
          >
            <ion-select-option *ngFor="let id of this.GegnerIDs" [value]="id">
              {{ id }}
            </ion-select-option>
          </ion-select>
        </ion-item>
        <ion-item>
          <ion-button (click)="this.spawnItem()">Spawn Item</ion-button>
        </ion-item>
      </ion-item-group>

      <ion-item-group *ngIf="this.spieler.inventar.length > 0">
        <ion-item-divider>
          <ion-label>Item entfernen</ion-label>
        </ion-item-divider>
        <ion-item>
          <ion-select
            [(ngModel)]="this.inventarIndex"
            label="Inventar-Item"
            labelPlacement="stacked"
          >
            <ion-select-option
              *ngFor="let item of this.spieler.inventar; let i = index"
              [value]="i"
            >
              {{ item.getItemText() }}
            </ion-select-option>
          </ion-select>
        </ion-item>
        <ion-item>
          <ion-button (click)="this.removeItem()">Entferne Item</ion-button>
        </ion-item>
      </ion-item-group>

      <ion-item-group>
        <ion-item-divider>
          <ion-label>Zufalls-Modifier</ion-label>
        </ion-item-divider>
        <ion-item>
          <ion-input
            type="number"
            [min]="-1"
            [max]="1"
            [step]="0.01"
            [(ngModel)]="this.StaticDebugLuck.luckModifier"
            label="Zufalls-Modifier"
            labelPlacement="stacked"
          ></ion-input>
        </ion-item>
      </ion-item-group>

      <ion-item-group>
        <ion-item-divider>
          <ion-label>Zeitreise</ion-label>
        </ion-item-divider>
        <ion-item>
          <ion-label>
            <table>
              <tr>
                <td>Aktiv seit:</td>
                <td>{{ this.formatDate(this.spieler.aktivSeit) }}</td>
              </tr>
              <tr>
                <td>Aktive Woche:</td>
                <td>{{ this.formatDate(this.spieler.aktiveWoche) }}</td>
              </tr>
              <tr>
                <td>Heute:</td>
                <td>{{ this.formatDate(this.today) }}</td>
              </tr>
            </table>
          </ion-label>
        </ion-item>
        <ion-item>
          <ion-checkbox [(ngModel)]="this.autoPlayBeforeTravel">
            AutoPlay vor Zeitreise
          </ion-checkbox>
        </ion-item>
        <ion-item>
          <ion-checkbox [(ngModel)]="this.moveAfterTravel">
            Feld hochzählen nach Zeitreise
          </ion-checkbox>
        </ion-item>
        <ion-item>
          <ion-input
            type="number"
            [min]="0"
            [max]="1"
            [step]="0.01"
            [(ngModel)]="this.truhenAnteil"
            label="AutoPlay: Truhen-Anteil"
            labelPlacement="stacked"
          ></ion-input>
        </ion-item>
        <ion-item>
          <ion-input
            type="number"
            [min]="0"
            [max]="100"
            [(ngModel)]="this.trackingMedianPerDay"
            label="AutoPlay: Median-TP pro Tag"
            labelPlacement="stacked"
          ></ion-input>
        </ion-item>
        <ion-item>
          <ion-input
            type="number"
            [min]="0"
            [max]="10"
            [(ngModel)]="this.trackingDeviationPerDay"
            label="AutoPlay: Mögliche TP Abweichung pro Tag"
            labelPlacement="stacked"
          ></ion-input>
        </ion-item>
        <ion-item>
          <ion-button (click)="this.autoPlay(this.spieler)"
            >Nur AutoPlay</ion-button
          >
          <ion-button (click)="this.timetravelOneWeek(this.spieler)">
            Eine Woche vorwärts
          </ion-button>
        </ion-item>
      </ion-item-group>

      <ion-item-group>
        <ion-item-divider>
          <ion-label>Simulation</ion-label>
        </ion-item-divider>
        <ion-item>
          <ion-input
            type="number"
            [min]="1"
            [max]="100"
            [(ngModel)]="this.playerCount"
            label="Simulation: Spieleranzahl"
            labelPlacement="stacked"
          ></ion-input>
        </ion-item>
        <ion-item>
          <ion-input
            type="number"
            [min]="1"
            [max]="52"
            [(ngModel)]="this.weekCount"
            label="Simulation: Dauer in Wochen"
            labelPlacement="stacked"
          ></ion-input>
        </ion-item>
        <ion-item>
          <ion-button (click)="this.simulate()">Simulieren</ion-button>
        </ion-item>
      </ion-item-group>

      <ion-item-group>
        <ion-item-divider>
          <ion-label>Spieler schreiben</ion-label>
        </ion-item-divider>
        <ion-item>
          <ion-button (click)="this.spieler.savePlayer(true)">
            Speichern
          </ion-button>
        </ion-item>
      </ion-item-group>

      <ion-item-group>
        <ion-item-divider>
          <ion-label>Testbuttons</ion-label>
        </ion-item-divider>
        <ion-item>
          <ion-button (click)="this.zeigeLootSpinner()">
            Loot-Spinner
          </ion-button>
        </ion-item>
      </ion-item-group>
    </ion-list>
  </ng-container>
  <ng-container *ngIf="this.spieler == null">
    Kein Spieler gefunden.
  </ng-container>
</ion-content>
